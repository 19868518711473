import React, { Component } from 'react';
import Slider from 'react-slick';
import Face from './images/face.jpg';
import Vernie from './images/Vernie.webp'
import LeftArrow from './images/Left-Arrow.png';
import RightArrow from './images/Right-Arrow.png';
import { Row, Col } from "reactstrap"
import Card from "@mui/material/Card"
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class ArtistCards extends Component {
	render() {
		const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
			<img src={LeftArrow} alt='prevArrow' {...props} />
		);

		const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
			<img src={RightArrow} alt='nextArrow' {...props} />
		);
		const settings = {
			dots: false,
			swipeToSlide: true,
			arrows: true,
			slidesToShow: 3,
			prevArrow: <SlickArrowLeft />,
			nextArrow: <SlickArrowRight />,
			responsive: [
				
				
				{
					breakpoint: 1099,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '4rem',
						slidesToShow: 2,
					},
				},
				
				
				{
					breakpoint: 811,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '1rem',
						slidesToShow: 2,
					},
				},
				

				{
					breakpoint: 660,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '5.5rem',
						slidesToShow: 1,
					},
				},

				{
					breakpoint: 450,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '4rem',
						slidesToShow: 1,
					},
				
			},
			
			
				{
					breakpoint: 421,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '3.6rem',
						slidesToShow: 1,
					},
				
			},


					{
						breakpoint: 400,
						settings: {
							arrows: true,
							centerMode: false,
							centerPadding: '3rem',
							slidesToShow: 1,
						},
					
				},
				{
					breakpoint: 374,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '2.5rem',
						slidesToShow: 1,
					},
				
			},

			{
				breakpoint: 352,
				settings: {
					arrows: true,
					centerMode: false,
					centerPadding: '2rem',
					slidesToShow: 1,
				},
			
		},

			],
		};
	return (
			<Row className="ArtistContainer">
					<Slider className="img-fluid" {...settings}>
							<Col lg={{ size:12, offset:0}} md={{size: 1, offset: 0}} sm={{size: 12, offset: 0}} xs={{size: 12, offset: 0}}>
							<Card className="mx-auto" sx={{maxWidth: 200, maxHeight: 320.15}}>
								<CardMedia style={{border: "2px solid gray", backgroundColor: "black"}}
									component="img"
									height="180"
									image= {Vernie}
									alt=""
								/>
								<CardContent style={{padding: '0px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: "black", border: "2px solid gray"}}>
									<Typography sx={{textAlign: "center", paddingTop: "5px"}} gutterBottom variant="h6" component="div" color="white" fontSize="16px">
										Vernie Bataliran
									</Typography>
									<Typography fontSize="10px" variant="body2" color="white" paddingTop="5px" paddingBottom="11.25px" textAlign="justify">
										Native to the Phillipines, this concept artist has six years experience in the gaming art industry. 
									</Typography>
									<a style={{textDecoration: "none"}} href= "https://www.facebook.com/BrainDeadCreation"><Button style={{paddingTop: "0px", fontSize: "11px", display: "block", margin: "0 auto"}} color="secondary" size="small">BrainDead Creation</Button></a>
								</CardContent>
							</Card>
							</Col>
							<Col lg={{ size:12, offset:0 }} md={{size: 1, offset: 0}} sm={{size: 12, offset: 0}} xs={{size: 12, offset: 0}}>
							<Card className="ArtistCard1 mx-auto" sx={{maxWidth: 200, maxHeight: 320.15}}>
								<CardMedia style={{border: "2px solid gray", backgroundColor: "black"}}
									component="img"
									height="180"
									image= {Face}
									alt=""
								/>
								<CardContent style={{padding: '0px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: "black", border: "2px solid gray"}}>
									<Typography sx={{textAlign: "center", paddingTop: "5px"}} gutterBottom variant="h6" component="div" color="white" fontSize="16px">
										Coming Soon
									</Typography>
									<Typography fontSize="10px" variant="body2" color="white" paddingTop="5px" paddingBottom="27.85px" textAlign="justify">
										Future artist coming soon. Artist can share a short introduction and social links here.
									</Typography>
									<Button style={{paddingTop: "0px", fontSize: "12px", display: "block", margin: "0 auto"}} color="secondary" size="small">Link</Button>
								</CardContent>
							</Card>
							</Col>
							<Col lg={{ size:12, offset:0 }} md={{size: 1, offset: 0}} sm={{size: 12, offset: 0}} xs={{size: 12, offset: 0}}>
							<Card className="ArtistCard1 mx-auto" sx={{maxWidth: 200, maxHeight: 320.15}}>
								<CardMedia style={{border: "2px solid gray", backgroundColor: "black"}}
									component="img"
									height="180"
									image= {Face}
									alt=""
								/>
								<CardContent style={{padding: '0px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: "black", border: "2px solid gray"}}>
									<Typography sx={{textAlign: "center", paddingTop: "5px"}} gutterBottom variant="h6" component="div" color="white" fontSize="16px">
										Coming Soon
									</Typography>
									<Typography fontSize="10px" variant="body2" color="white" paddingTop="5px" paddingBottom="27.85px" textAlign="justify">
										Future artist coming soon. Artist can share a short introduction and social links here.
									</Typography>
									<Button style={{paddingTop: "0px", fontSize: "12px", display: "block", margin: "0 auto"}} color="secondary" size="small">Link</Button>
								</CardContent>
							</Card>
							</Col>
							<Col lg={{ size:12, offset:0 }} md={{size: 1, offset: 0}} sm={{size: 12, offset: 0}} xs={{size: 12, offset: 0}}>
							<Card className="ArtistCard1 mx-auto" sx={{maxWidth: 200, maxHeight: 320.15}}>
								<CardMedia style={{border: "2px solid gray", backgroundColor: "black"}}
									component="img"
									height="180"
									image= {Face}
									alt=""
								/>
								<CardContent style={{padding: '0px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: "black", border: "2px solid gray"}}>
									<Typography sx={{textAlign: "center", paddingTop: "5px"}} gutterBottom variant="h6" component="div" color="white" fontSize="16px">
										Coming Soon
									</Typography>
									<Typography fontSize="10px" variant="body2" color="white" paddingTop="5px" paddingBottom="27.85px" textAlign="justify">
										Future artist coming soon. Artist can share a short introduction and social links here.
									</Typography>
									<Button style={{paddingTop: "0px", fontSize: "12px", display: "block", margin: "0 auto"}} color="secondary" size="small">Link</Button>
								</CardContent>
							</Card>
							</Col>
							<Col lg={{ size:12, offset:0 }} md={{size: 1, offset: 0}} sm={{size: 12, offset: 0}} xs={{size: 12, offset: 0}}>
							<Card className="ArtistCard1 mx-auto" sx={{maxWidth: 200, maxHeight: 320.15}}>
								<CardMedia style={{border: "2px solid gray", backgroundColor: "black"}}
									component="img"
									height="180"
									image= {Face}
									alt=""
								/>
								<CardContent style={{padding: '0px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: "black", border: "2px solid gray"}}>
									<Typography sx={{textAlign: "center", paddingTop: "5px"}} gutterBottom variant="h6" component="div" color="white" fontSize="16px">
										Coming Soon
									</Typography>
									<Typography fontSize="10px" variant="body2" color="white" paddingTop="5px" paddingBottom="27.85px" textAlign="justify">
										Future artist coming soon. Artist can share a short introduction and social links here.
									</Typography>
									<Button style={{paddingTop: "0px", fontSize: "12px", display: "block", margin: "0 auto"}} color="secondary" size="small">Link</Button>
								</CardContent>
							</Card>
							</Col>
					</Slider>
			</Row>
			
		);
	}
}
