import myLogo from "./images/myLogo.png"
import CL from "./images/ComplexLabs.png"
import AboutUs from "./images/AboutUs.png"
import Artist from "./images/Artist.png"
import Projects from "./images/Projects.png"
import ContactUsUnderline from "./images/ContactUs-Underline.png"
import { Link } from 'react-router-dom';
import React from "react"
import ContactForm from "./ContactForm"
import { Container, Row, Col } from "reactstrap";
import Twitter from "./images/TwitterIcon.png"
import Insta from "./images/InstagramIcon1.png"
import Discord from "./images/discordIcon.png"
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactUs = () => {
	return (
		<div style={{height: "105vh", overflowX: "hidden"}}>
		<Container style={{paddingTop: 30, maxWidth:1450,}} id="header">
		<Row>
				<Col xs={{size: 11, offset: 1}} md={{size: 6, offset: 0}} lg="6">
					<Link to='/'><img className='icon' src={myLogo} alt='' /></Link>
					<Link to='/'><img className='complexLabs' src={CL} alt='' /></Link>
				</Col>
				<Col style={{paddingTop:23}}>
					<Row>
						<Col xs={{size: 5, offset: 1}} md={{size: 6, offset: 0}} lg="3">
							<Link to='/aboutus'><img className='aboutUs' src={AboutUs} alt='' /></Link>
						</Col>
						<Col xs="6" md="6" lg="3"  style={{paddingLeft: 40}}>
							<Link to='/artist'><img className='artist' src={Artist} alt=''/></Link>
						</Col>
						<Col xs={{size: 5, offset: 1}} md={{size: 6, offset: 0}} lg="3">
							<Link to='/projects'><img className='projects' src={Projects} alt='' /></Link>
						</Col>
						<Col xs="6" md="6" lg="3">
							<Link to='/contactus'><img className='contactUs' src={ContactUsUnderline} alt='' /></Link>
						</Col>
					</Row>
				</Col>
			</Row>
		<Container style={{paddingTop: 30, maxWidth:1450,}}>
			<Row>
				<Col lg={{ size: 5}}>
				<p className="ContactUsText">
					We look forward to hearing from you! Please feel free to contact our team with any questions, concerns, or suggestions and we will respond to you message as quickly as possible. You can use the icons below to join any of our social networks and link up with us directly, meet other members of the community, discuss project ideas, and help us continue to grow.  
				</p>
				</Col>
				<Col>
					<Row>
						<Col lg={{ offset: 1}} sm={{ offset: 0}} xs={{offset: 0}} >
						<ContactForm />
						</Col>
					</Row>
				</Col>
			</Row>
			<Row style={{paddingTop: "17px", display: "flex", justifyContent: "center"}}>
				<Col lg={{size:2, offset: 1}} md={{size:2, offset: 1}} sm={{size: 3}} xs={{size: 3}}>
				<a href="https://www.instagram.com/complexlabs.io/"><img src={Insta} alt="" className="Insta"></img></a>
				</Col>
				<Col lg={{size:2}} md={{size:2}} sm={{size: 3}} xs={{size: 3}}>
				<a href="https://www.twitter.com/Complexlabs_io/"><img src={Twitter} alt="" className="Twitter"></img></a>
				</Col>
				<Col lg={{size:2}} md={{size:2}} sm={{size: 3}} xs={{size: 3}}>
				<a href="https://discord.gg/VxR4vFKgd2"><img src={Discord} alt="" className="Discord" ></img></a>
				</Col>
			</Row>
		</Container>
		</Container>
		</div>
	);
};

export default ContactUs;
