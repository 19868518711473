import React from "react";
import { Container, Row, Col } from "reactstrap";
import myLogo from "./images/myLogo.png";
import CL from "./images/ComplexLabs.png";
import AboutUs from "./images/AboutUs.png";
import Artist from "./images/Artist.png";
import Projects from "./images/Projects.png";
import ContactUs from "./images/ContactUs.png";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = (props) => {
	return (
		<Container style={{paddingTop: 30, maxWidth:1450,}} id="header">
			<Row>
				<Col xs={{size: 11, offset: 1}} md={{size: 6, offset: 0}} lg="6">
					<Link to='/'><img className='icon' src={myLogo} alt='' /></Link>
					<Link to='/'><img className='complexLabs' src={CL} alt='' /></Link>
				</Col>
				<Col style={{paddingTop:23}}>
					<Row>
						<Col xs={{size: 5, offset: 1}} md={{size: 6, offset: 0}} lg="3">
							<Link to='/aboutus'><img className='aboutUs' src={AboutUs} alt='' /></Link>
						</Col>
						<Col xs="6" md="6" lg="3"  style={{paddingLeft: 40}}>
							<Link to='/artist'><img className='artist' src={Artist} alt=''/></Link>
						</Col>
						<Col xs={{size: 5, offset: 1}} md={{size: 6, offset: 0}} lg="3">
							<Link to='/projects'><img className='projects' src={Projects} alt='' /></Link>
						</Col>
						<Col xs="6" md="6" lg="3">
							<Link to='/contactus'><img className='contactUs' src={ContactUs} alt='' /></Link>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default Header;