import SRR from "./images/RRs.gif"
import helmet from "./images/Helmet.gif"
import ComingSoon from "./images/255161.jpg"
import React from "react"
import { Row, Col } from "reactstrap"
import Card from "@mui/material/Card"
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const Cards = () => {
    return (
        <Row className="cards pt-sm-0 mt-sm-0 pt-md-5 mt-lg-4">
            <Col lg="2" md="4" sm={{size:12}} xs={{size:12}} style={{paddingBottom: 30}}>
                <Card sx={{ maxWidth: 197, maxHeight: 325}} className="card1 mx-auto">
                    <CardMedia style={{border: "2px solid white"}}
                        component="img"
                        height="180"
                        image= {SRR}
                        alt="Radical Rat"
                    />
                    <CardContent sx={{backgroundColor: "black", textAlign: "center"}}>
                        <Typography gutterBottom variant="h5" component="div" color="white">
                            Radical Rats
                        </Typography>
                        <Typography variant="body2" color="white">
                           Cost: 35 MATIC
                        </Typography>
                    </CardContent>
                    <CardActions  sx={{backgroundColor: "black"}} className="buybutton">
                       <Link to="/radicalrats"><Button variant="contained" size="small">BUY NOW</Button> </Link>
                    </CardActions>
                </Card>
            </Col>
            <Col lg="2" md="4" sm={{size:12}} xs={{size:12}} style={{paddingBottom: 30}}>
            <Card sx={{ maxWidth: 197, maxHeight: 325}} className="card2 mx-auto">
                    <CardMedia style={{border: "2px solid white"}}
                        component="img"
                        height="180"
                        image= {helmet}
                        alt=""
                    />
                    <CardContent sx={{backgroundColor: "black", textAlign: "center"}}>
                        <Typography gutterBottom variant="h5" component="div" color="white">
                            AA Helmets
                        </Typography>
                        <Typography variant="body2" color="white">
                           Cost: 3.3 MATIC
                        </Typography>
                    </CardContent>
                    <CardActions  sx={{backgroundColor: "black"}} className="buybutton">
                       <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Faugmented-avatars.com%2Fhelmets-mint%2Findex%3Ffbclid%3DIwAR1vqkVXnoeJbuEHwWsnlqHOuQ2LCjw8Whn4MmdXaSe0QLLfEnlHpS2S-7Q&h=AT1ttjDYJ64dTMhdUh4UOAJdMXPViBRdlQ_MxpjRCoAlLFpRgE2SwfoHfFfL3CNs7Z24e2-k_jKNAd1rVeVULcVPJvGlHcp97SjOTHAZZNcb7v1eRo1KcL6UIKHJZ7tlXtc"><Button variant="contained" size="small">BUY NOW</Button></a>
                    </CardActions>
                </Card>
            </Col>
            <Col lg="2" md="4"sm={{size:12}} xs={{size:12}} style={{paddingBottom: 30}}>
            <Card sx={{ maxWidth: 197, maxHeight: 325}} className="card3 mx-auto">
                    <CardMedia style={{border: "2px solid white"}}
                        component="img"
                        height="180"
                        image= {ComingSoon}
                        alt=""
                    />
                   <CardContent sx={{backgroundColor: "black", textAlign: "center"}}>
                        <Typography gutterBottom variant="h5" component="div" color="white">
                            New Project
                        </Typography>
                        <Typography variant="body2" color="white">
                           Coming Soon
                        </Typography>
                    </CardContent>
                    <CardActions  sx={{backgroundColor: "black"}} className="buybutton">
                        <Button disabled variant="contained" size="small">BUY NOW</Button>
                    </CardActions>
                </Card>
            </Col>
            <Col lg="2" md="4" sm={{size:12}} xs={{size:12}} style={{paddingBottom: 30}}>
            <Card sx={{ maxWidth: 197, maxHeight: 325}} className="card4 mx-auto">
                    <CardMedia style={{border: "2px solid white"}}
                        component="img"
                        height="180"
                        image= {ComingSoon}
                        alt=""
                    />
                   <CardContent sx={{backgroundColor: "black", textAlign: "center"}}>
                        <Typography gutterBottom variant="h5" component="div" color="white">
                            New Project
                        </Typography>
                        <Typography variant="body2" color="white">
                           Coming Soon
                        </Typography>
                    </CardContent>
                    <CardActions  sx={{backgroundColor: "black"}} className="buybutton">
                        <Button disabled variant="contained" size="small">BUY NOW</Button>
                    </CardActions>
                </Card>
            </Col>
            <Col lg="2" md="4" sm={{size:12}} xs={{size:12}} style={{paddingBottom: 30}}>
            <Card sx={{ maxWidth: 196, maxHeight: 325}} className="card5 mx-auto">
                    <CardMedia style={{border: "2px solid white"}}
                        component="img"
                        height="180"
                        image= {ComingSoon}
                        alt=""
                    />
                   <CardContent sx={{backgroundColor: "black", textAlign: "center"}}>
                        <Typography gutterBottom variant="h5" component="div" color="white">
                            New Project
                        </Typography>
                        <Typography variant="body2" color="white">
                           Coming Soon
                        </Typography>
                    </CardContent>
                    <CardActions  sx={{backgroundColor: "black"}} className="buybutton">
                        <Button disabled variant="contained" size="small">BUY NOW</Button>
                    </CardActions>
                </Card>
            </Col>
        </Row>
    )
}

export default Cards
