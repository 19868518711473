import Header from "./Header"
import HomepageBody from "./HomepageBody"
import 'bootstrap/dist/css/bootstrap.min.css';

const Homepage = () => {
    return (
        <div style={{overflowX: "hidden"}}>
            <Header />
            <HomepageBody />
        </div>
    )
}

export default Homepage
