import React, { useEffect, useState} from "react";
import Onboard from "bnc-onboard";
import ABI from "./abi.json"
//import { useDispatch, useSelector } from "react-redux";
//import { connect } from "../../redux/blockchain/blockchainActions";
//import { fetchData } from "../../redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import Web3 from "web3";

const contractAddress = "0xBe0Ad319d562d5fdf123C1F35B484371841Aa374";

const RPC_URL = "https://polygon-mainnet.infura.io/v3/69013b1656cc4e56a4196c77ec1747d6";


const wallets = [
  { walletName: "trust", preferred: true, rpc: RPC_URL },
  { walletName: "metamask", preferred: true },
  { walletName: "ledger", rpc: RPC_URL, preferred: true },
  { walletName: "walletConnect", preferred: true, rpc: {137: "https://polygon-mainnet.infura.io/v3/69013b1656cc4e56a4196c77ec1747d6"} },
];

var web3;
var myContract;

const onboard = Onboard({
  dappId: "b1da45a1-6df5-4a0e-a0de-5dbd45cc1d15",
  networkId: 137,
  walletSelect: {
      wallets: wallets
  },
  subscriptions: {
      wallet: (wallet) => {
          window.localStorage.setItem("selectedWallet", wallet.name);
          web3 = new Web3(wallet.provider);
          console.log(wallet.name);
          myContract = new web3.eth.Contract(ABI, contractAddress)
      }
  }
});

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
  border: none;
  background-color: var(--lighter-border);
  padding: 10px;
  font-weight: bold;
  color: var(--accent-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 2px rgba(250, 250, 250, .5);
  -webkit-box-shadow: 0px 0px 0px 2px rgba(250, 250, 250, .5);
  -moz-box-shadow: 0px 0px 0px 2px rgba(250, 250, 250, .5);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 18px;
  margin-top: -12px;
  border-radius: 100%;
  border: none;
  background-color: var(--lighter-border);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--accent-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 2px rgba(250, 250, 250, .5);
  -webkit-box-shadow: 0px 0px 0px 2px rgba(250, 250, 250, .5);
  -moz-box-shadow: 0px 0px 0px 2px rgba(250, 250, 250, .5);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 20px 11px 5px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--accent-border);
  background-color: var(--accent);
  border-radius: 10%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--accent-text);
  text-decoration: none;
`;

function MintDappLanding() {
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [connected, setConnected] = useState(false);
  const [totalMinted,setTotalMinted] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    RR_TEXT: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 1,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  async function login() {
    const walletSelected = await onboard.walletSelect();
    if (walletSelected) {
        await onboard.walletCheck();
        setConnected(true)
        getTotalMinted();
    }else{console.log("Please connect a wallet")}
  }

  async function getTotalMinted() {
    myContract.methods.totalSupply()
        .call()
        .then((res) => {
            setTotalMinted(res)
        })
        .catch((err) => {
            console.log(err);
        })
  }

  const claimNFTs = async () => {
    let cost = CONFIG.WEI_COST;
    let newGasLimit = 500000;
    if (newGasLimit < 300000){
      newGasLimit = 300000
    }
    let totalCostWei = String(cost * mintAmount);
    console.log("New Gas Limit is " + newGasLimit);
    console.log("Cost: ", totalCostWei);
    setFeedback(`Minting your token from ${CONFIG.NFT_NAME}, this may take a minute...`);
    setClaimingNft(true);
    const currentState = onboard.getState();
    myContract.methods.mint(currentState.address, mintAmount)
        .send({ from: currentState.address, value: totalCostWei, gasLimit: newGasLimit, gasPrice: 35000000000})
        .on("transactionHash", function (hash) {
            console.log(hash);
        })
        .on("confirmation", function (confirmationNumber, reciept) {
            console.log(confirmationNumber);
        })
        .on("receipt", function (receipt) {
            console.log(receipt);
            setFeedback(
           `Congratulations! Your purchase from ${CONFIG.NFT_NAME} was successful! You can visit Opensea.io and connect your wallet to view it.`
         );
            setClaimingNft(false);
        })
        .on("error", function (error, receipt) {
            console.log(error);
        })
        .catch(err => {
            console.log(err);
        });

  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if(connected){
      return onboard.getState().address
    }else{
      return "Please connect an acceptable wallet"
    }
    // if (blockchain.account !== "" && blockchain.smartContract !== null) {
    //   dispatch(fetchData(blockchain.account));
    // }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  // useEffect(() => {
  //   getData();
  //   // eslint-disable-next-line
  // }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--accent-border)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.webp" : null}
      >
        <Link to="/"><StyledLogo alt={"logo"} src={"/config/images/logo.png"} /></Link>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/RRs.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 0,
              borderRadius: 24,
              border: "4px solid var(--accent-border)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {totalMinted} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(totalMinted) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.RR_TEXT} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  To mint using a mobile device please utilize Wallet Connect. 
                </s.TextDescription>
                <s.SpacerSmall />
                {/*blockchain.account === "" ||
                blockchain.smartContract === null*/ !connected? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Please connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        login();
                        //dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {/*{blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                        ) : null}*/}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/RRs.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--accent-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action. 
          </s.TextDescription>
          <s.SpacerSmall />
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default MintDappLanding;
