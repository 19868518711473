import myLogo from "./images/myLogo.png"
import CL from "./images/ComplexLabs.png"
import AboutUs from "./images/AboutUs.png"
import ArtistUnderline from "./images/Artist-Underline.png"
import Projects from "./images/Projects.png"
import ContactUs from "./images/ContactUs.png"
import ArtistCards from './ArtistCards';
import { Link } from 'react-router-dom';
import React from "react"
import { Container, Row, Col } from "reactstrap";
import Twitter from "./images/TwitterIcon.png"
import Insta from "./images/InstagramIcon1.png"
import Discord from "./images/discordIcon.png"
import 'bootstrap/dist/css/bootstrap.min.css';

const Artist = () => {
	return (
		<div style={{height: "105vh", overflowX: "hidden"}}>
			<Container style={{paddingTop: 30, maxWidth:1450}} id="header">
			<Row>
				<Col xs={{size: 11, offset: 1}} md={{size: 6, offset: 0}} lg="6">
					<Link to='/'><img className='icon' src={myLogo} alt='' /></Link>
					<Link to='/'><img className='complexLabs' src={CL} alt='' /></Link>
				</Col>
				<Col style={{paddingTop:23}}>
					<Row>
						<Col xs={{size: 5, offset: 1}} md={{size: 6, offset: 0}} lg="3">
							<Link to='/aboutus'><img className='aboutUs' src={AboutUs} alt='' /></Link>
						</Col>
						<Col xs="6" md="6" lg="3"  style={{paddingLeft: 40}}>
							<Link to='/artist'><img className='artist' src={ArtistUnderline} alt=''/></Link>
						</Col>
						<Col xs={{size: 5, offset: 1}} md={{size: 6, offset: 0}} lg="3">
							<Link to='/projects'><img className='projects' src={Projects} alt='' /></Link>
						</Col>
						<Col xs="6" md="6" lg="3">
							<Link to='/contactus'><img className='contactUs' src={ContactUs} alt='' /></Link>
						</Col>
					</Row>
				</Col>
			</Row>
			</Container>
			<ArtistCards />
			<Row style={{display: "flex", justifyContent: "center"}}>
				<Col lg={{size:2, offset: 1}} md={{size:2, offset: 1}} sm={{size: 3}} xs={{size: 3}}>
				<a href="https://www.instagram.com/complexlabs.io/"><img src={Insta} alt="" className="Insta" style={{position: "relative", bottom: "-76px"}}></img></a>
				</Col>
				<Col lg={{size:2}} md={{size:2}} sm={{size: 3}} xs={{size: 3}}>
				<a href="https://www.twitter.com/Complexlabs_io/"><img src={Twitter} alt="" className="Twitter" style={{position: "relative", bottom: "-76px"}}></img></a>
				</Col>
				<Col lg={{size:2}} md={{size:2}} sm={{size: 3}} xs={{size: 3}}>
				<a href="https://discord.gg/VxR4vFKgd2"><img src={Discord} alt="" className="Discord" style={{position: "relative", bottom: "-76px"}}></img></a>
				</Col>
			</Row>
		</div>
	);
};

export default Artist;
