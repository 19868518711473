import React, { Component } from 'react';
import Slider from 'react-slick';
import ComingSoon from './images/25516.jpg';
import RRBanner from "./images/RR-Banner.jpg"
import LeftArrow from './images/Left-Arrow.png';
import RightArrow from './images/Right-Arrow.png';
import { Row, Col } from "reactstrap"
import Card from "@mui/material/Card"
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


export default class ProjectCards extends Component {
	render() {
		const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
			<img src={LeftArrow} alt='prevArrow' {...props} />
		);

		const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
			<img src={RightArrow} alt='nextArrow' {...props} />
		);
		const settings = {
			dots: false,
			swipeToSlide: true,
			arrows: true,
			centerPadding: '60px',
			slidesToShow: 3,
			prevArrow: <SlickArrowLeft />,
			nextArrow: <SlickArrowRight />,
			responsive: [

				{
					breakpoint: 1349,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '4rem',
						slidesToShow: 2,
					},
				},
				

				{
					breakpoint: 820,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '40px',
						slidesToShow: 2,
					},
				},

				{
					breakpoint: 799,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '40px',
						slidesToShow: 1,
					},
				},

				{
					breakpoint: 480,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '80px',
						slidesToShow: 1,
					},
				},
			
				{
					breakpoint: 429,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '43px',
						slidesToShow: 1,
					},
				},
			
			{
				breakpoint: 420,
				settings: {
					arrows: true,
					centerMode: false,
					centerPadding: '35px',
					slidesToShow: 1,
				},
			},

				{
					breakpoint: 391,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '19px',
						slidesToShow: 1,
					},
				},

				{
					breakpoint: 365,
					settings: {
						arrows: true,
						centerMode: false,
						centerPadding: '10px',
						slidesToShow: 1,
					},
				},
			],
		};
		return (
			<Row className="ProjectContainer">
					<Slider className="img-fluid" {...settings}>
							<Col lg={{ size:12, offset:0 }} md={{size: 1, offset: 0}} sm={{size: 12, offset: 0}} xs={{size: 12, offset: 0}}>
							<Card className="mx-auto" sx={{maxWidth: 252, maxHeight: 319.367}}>
								<CardMedia style={{border: "2px solid gray", backgroundColor: "black"}}
									component="img"
									height="212.15"
									image= {RRBanner}
									alt=""
								/>	
								<CardContent style={{padding: '0px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: "black", border: "2px solid gray", textAlign: "center"}}>
								<Typography sx={{textalign: "center", paddingTop: "5px"}} gutterBottom variant="h6" component="div" color="white" fontSize="18px">
										Radical Rats
									</Typography>
									<Typography fontSize="12px" variant="body2" color="white" paddingTop="5px" paddingBottom="11.25px" textalign="center">
										Cost: 0.01 ETH
									</Typography>
									<Link to="/radicalrats"><Button style={{paddingTop: "0px", fontSize: "14px", textAlign: "center"}} textalign="center" color="secondary" size="medium">Buy</Button></Link>
								</CardContent>
							</Card>
							</Col>
							<Col lg={{ size:12, offset:0 }} md={{size: 1, offset: 0}} sm={{size: 12, offset: 0}} xs={{size: 12, offset: 0}}>
							<Card sx={{maxWidth: 252, maxHeight: 319.367}} className="ArtistCard1 mx-auto" >
								<CardMedia  style={{border: "2px solid gray", backgroundColor: "black"}}
									component="img"
									height="212.15"
									image= {ComingSoon}
									alt=""
								/>
								<CardContent style={{padding: '0px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: "black", border: "2px solid gray", textAlign: "center"}}>
									<Typography sx={{textalign: "center", paddingTop: "5px"}} gutterBottom variant="h6" component="div" color="white" fontSize="18px">
										Test
									</Typography>
									<Typography fontSize="12px" variant="body2" color="white" paddingTop="5px" paddingBottom="11.25px" textalign="center">
										Test
									</Typography>
									<Button size="small">Link</Button>
									</CardContent>
							</Card>
							</Col>
							<Col lg={{ size:12, offset:0 }} md={{size: 1, offset: 0}} sm={{size: 12, offset: 0}} xs={{size: 12, offset: 0}}>
							<Card sx={{maxWidth: 252, maxHeight: 319.367}} className="ArtistCard1 mx-auto">
								<CardMedia  style={{border: "2px solid gray", backgroundColor: "black"}}
									component="img"
									height="212.15"
									image= {ComingSoon}
									alt=""
								/>
								<CardContent style={{padding: '0px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: "black", border: "2px solid gray", textAlign: "center"}}>
									<Typography sx={{textalign: "center", paddingTop: "5px"}} gutterBottom variant="h6" component="div" color="white" fontSize="18px">
										Test
									</Typography>
									<Typography fontSize="12px" variant="body2" color="white" paddingTop="5px" paddingBottom="11.25px" textalign="center">
										Test
									</Typography>
									<Button size="small">Link</Button>
									</CardContent>
							</Card>
							</Col>
							<Col lg={{ size:12, offset:0 }} md={{size: 1, offset: 0}} sm={{size: 12, offset: 0}} xs={{size: 12, offset: 0}}>
							<Card sx={{maxWidth: 252, maxHeight: 319.367}} className="ArtistCard1 mx-auto">
								<CardMedia  style={{border: "2px solid gray", backgroundColor: "black"}}
									component="img"
									height="212.15"
									image= {ComingSoon}
									alt=""
								/>
								<CardContent style={{padding: '0px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: "black", border: "2px solid gray", textAlign: "center"}}>
									<Typography sx={{textalign: "center", paddingTop: "5px"}} gutterBottom variant="h6" component="div" color="white" fontSize="18px">
										Test
									</Typography>
									<Typography fontSize="12px" variant="body2" color="white" paddingTop="5px" paddingBottom="11.25px" textalign="center">
										Test
									</Typography>
									<Button size="small">Link</Button>
									</CardContent>
							</Card>
							</Col>
							<Col lg={{ size:12, offset:0 }} md={{size: 1, offset: 0}} sm={{size: 12, offset: 0}} xs={{size: 12, offset: 0}}>
							<Card sx={{maxWidth: 252, maxHeight: 319.367}} className="ArtistCard1 mx-auto">
								<CardMedia  style={{border: "2px solid gray", backgroundColor: "black"}}
									component="img"
									height="212.15"
									image= {ComingSoon}
									alt=""
								/>
								<CardContent style={{padding: '0px', paddingLeft: "20px", paddingRight: "20px", backgroundColor: "black", border: "2px solid gray", textAlign: "center"}}>
									<Typography sx={{textalign: "center", paddingTop: "5px"}} gutterBottom variant="h6" component="div" color="white" fontSize="18px">
										Test
									</Typography>
									<Typography fontSize="12px" variant="body2" color="white" paddingTop="5px" paddingBottom="11.25px" textalign="center">
										Test
									</Typography>
									<Button size="small">Link</Button>
									</CardContent>
							</Card>
							</Col>
					</Slider>
			</Row>
			
		);
	}
}