import Cards from "./Cards"
import React from "react";
import { Container, Row, Col } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const HomepageBody = () => {
    return (
        <Container style={{maxWidth:1450, overflowX: "hidden"}}>
            <Row style={{paddingTop: 60}}>
                <Col xs="12" sm="12" md="12" lg="5">
                    <p className="introText">Deploy, discover, and collect amazing NFTs </p>
                </Col>
                <Col style={{paddingTop: 18}}> 
                    <Row>
                        <Col lg={{ size: 10, offset: 2}} md={{size:12, offset:0}} xs={{size:10, offset: 1}}>
                            <p className="homepageText">ComplexLabs is still seeking to partner with talented artist and developers for future NFT projects. To learn more please check out our About Us section and feel free to contact us with any and all questions. Building something great takes time. While we are still under construction we hope you will take the time to explore our site and check back regularly for new content. </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
                <Cards />
        </Container>
    )
}

export default HomepageBody
