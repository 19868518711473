import Banner from "./images/Banner.webp"
import myLogo from "./images/myLogo.png"
import CL from "./images/ComplexLabs.png"
import Twitter from "./images/TwitterIcon.png"
import Insta from "./images/InstagramIcon1.png"
import Discord from "./images/discordIcon.png"
import AboutUsUnderline from "./images/AboutUs-Underline.png"
import Artist from "./images/Artist.png"
import Projects from "./images/Projects.png"
import ContactUs from "./images/ContactUs.png"
import { Link } from 'react-router-dom';
import React from "react"
import { Container, Row, Col } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutUs = () => {
	return (
		<div style={{height: "105vh", overflowX: "hidden"}}>
			<Container style={{paddingTop: 30, maxWidth:1450,}} id="header">
			<Row>
				<Col xs={{size: 11, offset: 1}} md={{size: 6, offset: 0}} lg="6">
					<Link to='/'><img className='icon' src={myLogo} alt='' /></Link>
					<Link to='/'><img className='complexLabs' src={CL} alt='' /></Link>
				</Col>
				<Col style={{paddingTop:23}}>
					<Row>
						<Col xs={{size: 5, offset: 1}} md={{size: 6, offset: 0}} lg="3">
							<Link to='/aboutus'><img className='aboutUs' src={AboutUsUnderline} alt='' /></Link>
						</Col>
						<Col xs="6" md="6" lg="3"  style={{paddingLeft: 40}}>
							<Link to='/artist'><img className='artist' src={Artist} alt=''/></Link>
						</Col>
						<Col xs={{size: 5, offset: 1}} md={{size: 6, offset: 0}} lg="3">
							<Link to='/projects'><img className='projects' src={Projects} alt='' /></Link>
						</Col>
						<Col xs="6" md="6" lg="3">
							<Link to='/contactus'><img className='contactUs' src={ContactUs} alt='' /></Link>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="12" sm="12">
					<p className='aboutustext pl-xs-0 pl-sm-0 ml-md-5 pl-lg-0 mx-md-auto'>
						Here at ComplexLabs our goal is simple, help others break into the
						world of NFTs. We understand that not everyone has the time or ability
						to create a smart contract for their project, not everyone can sit
						down with procreate or photoshop and create a masterpiece, and not
						everyone can create Dapps to power new projects and put them in the
						hands of the user. All of us have different abilities and skillsets
						and this is the idea that inspired us to create this platform. It is
						our dream to bring users together to build and create the things they
						never thought possible when they were working alone. By working
						together and creating a home for artist to display and sell their
						projects we hope to lead teams in successful ventures time and time
						again. Please feel free to contact us with any questions or to discuss
						your dream project and team needs and don't forget to check back
						regularly for new projects as we are only just getting started!
					</p>
				</Col>
				<Col>
					<Row>
						<Col sm={{size: 12}} md={{size: 12}} lg={{size: 12}} >
							<img className="banner img-fluid mx-auto" src={Banner} style={{scale: "80%"}} alt=""></img>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row style={{paddingTop: "95px", display: "flex", justifyContent: "center"}}>
				<Col lg={{size:2, offset: 1}} md={{size:2, offset: 1}} sm={{size: 3}} xs={{size: 3}}>
				<a href="https://www.instagram.com/complexlabs.io/"><img src={Insta} alt="" className="Insta"></img></a>
				</Col>
				<Col lg={{size:2}} md={{size:2}} sm={{size: 3}} xs={{size: 3}}>
				<a href="https://www.twitter.com/Complexlabs_io/"><img src={Twitter} alt="" className="Twitter"></img></a>
				</Col>
				<Col lg={{size:2}} md={{size:2}} sm={{size: 3}} xs={{size: 3}}>
				<a href="https://discord.gg/VxR4vFKgd2"><img src={Discord} alt="" className="Discord"></img></a>
				</Col>
			</Row>
			</Container>
		</div>
	);
};

export default AboutUs;
