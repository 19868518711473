import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactForm = () => {
    const [status, setStatus] = useState("Submit");
    const handleSubmit = async (e) => {
      e.preventDefault();
      setStatus("Sending...");
      const { name, email, message } = e.target.elements;
      let details = {
        name: name.value,
        email: email.value,
        message: message.value
      };
      let response = await fetch("https://support.complexlabs.io:8443/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8"
        },
        body: JSON.stringify(details)
      });
      setStatus("Submit");
      let result = await response.json();
      alert(result.status);
    };

    return (
      <div className="contactuscard img-fluid mx-auto">
        <form style={{width: 700, height: 500}} onSubmit={handleSubmit}>
        <h1 style={{color: "white", textAlign: "center", paddingTop: "20px"}}> Contact Us </h1>
        <label style={{paddingTop: "10px"}} className="ContactUsLabel" htmlFor="name">Name:</label>
        <input className="ContactUsInput" type="text" id="name" required />
        <label className="ContactUsLabel" htmlFor="email">Email:</label>
        <input className="ContactUsInput" type="email" id="email" required />
        <label className="ContactUsLabel" htmlFor="message">Message:</label>
        <textarea className="ContactUsMessage" id="message" required />
      <button className="SubmitButton" type="submit">{status}</button>
    </form>
    </div>
  );
};

export default ContactForm
